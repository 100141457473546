<template>
    <div v-if="userData === undefined && !userCoffeepreference">
        <modal-home :show-close-btn="true" :rounded="true" :close-on-outside-click="true" modal-class="relative top-28 md:top-0 md:max-w-xl" header-style="background: white; color: #465f87;" ref="modal" v-on:closed="onModalClosed">
            <slot slot-scope="modal">
                <div class="text-center md:text-left md:pl-72 relative bottom-44 md:bottom-2 md:mb-10">
                    <transition name="fade">
                        <img v-show="imgLoaded" :src="require('home/campaign-popup.png')" class="w-64 md:absolute md:bottom-4 md:left-1" alt="Campaign" v-on:load="imgLoaded = true">
                    </transition>
                    <div class="relative top-8 md:-top-8">
                        <h1 class="text-5xl md:text-left">Get 1/2 Off</h1>
                        <p class="half-off">And we won't play any slick tricks like baiting you, then making you pre-pay for future orders.</p>
                        <a href="/onboarding" class="block w-72 md:w-64 m-auto md:m-0 mt-10 py-4 md:py-2 bg-home-purple rounded-2xl text-center tracking-widest hover:bg-home-purple-light duration-300">
                            GET STARTED
                        </a>
                    </div>
                </div>
            </slot>
        </modal-home>
    </div>
    <div v-else-if="userData != undefined">
        <modal-home :show-close-btn="true" :rounded="true" modal-class="relative top-28 md:top-0 md:max-w-xl" header-style="background: white; color: #465f87;" ref="modal" v-on:closed="brewModalClosed">
            <slot slot-scope="modal">
                <div class="text-center">
                    <div class="relative top-8 md:-top-8">
                        <h2 class="text-5xl md:text-left">Uh oh :( sadly you have {{ userData.suggested_product_ids.length }} matching coffees in your Suggested Brew Queue.</h2>
                        <p>Please try selecting a different "Grind" Setting or select more "Sizes" in your Suggested Brew Queue's settings. Just open your Brew Queue and click the Equalizer icon at the top of the Suggested coffee list (located just below the main Brew Queue) to adjust/add more selections.</p>
                    </div>
                </div>
            </slot>
        </modal-home>
    </div>
    <div v-else-if="userCoffeepreference">
        <modal-home :show-close-btn="true" :rounded="true" modal-class="relative top-28 md:top-0 md:max-w-xl" header-style="background: white; color: #465f87;" ref="modal" v-on:closed="brewModalClosed">
            <slot slot-scope="modal">
                <div class="text-center">
                    <div class="relative top-8 md:-top-8">
                        <h2 class="text-5xl md:text-left">Uh oh :( sadly you forgot to take Quiz</h2>
                        <p>We need to know your individual <b><u><a href="/profile/brew_log">coffee preferences</a></u></b> so our matching algorithm can find you the best coffees that are sure to be a complete delight, anytime, anywhere.<br> <br>Please click on the Brew Log button to complete the 2 min. coffee quiz.</p>
                        <a href="/profile/brew_log" class="block w-72 md:w-64 m-auto mt-10 py-4 md:py-2 bg-home-purple rounded-2xl text-center tracking-widest hover:bg-home-purple-light duration-300">
                            Brew Log
                        </a>
                    </div>
                </div>
            </slot>
        </modal-home>
    </div>
</template>

<script>
    import ModalHome from './modal-home';

    export default {
        components: {
            ModalHome
        },
        props: {
            userData: Object,
            userCoffeepreference: Boolean
        },
        data: function() {
            return  {
                imgLoaded: false
            }
        },
        methods: {
            onModalClosed() {
                localStorage.setItem('campaignPopupClosedAt', new Date().toString());
            },
            brewModalClosed(){
                localStorage.setItem('campaignBrewPopupClosedAt', new Date().toString());
            }
        },
        mounted: function() {
            // This will be Jan 01 1970 if campaignPopupClosedAt is null.
            const campaignPopupClosedAt = new Date(localStorage.getItem('campaignPopupClosedAt'));
            const threeDaysAgo =  new Date(new Date().setDate(new Date().getDate()-3));

            const campaignBrewPopupClosedAt = new Date(localStorage.getItem('campaignBrewPopupClosedAt'));
            const oneDayAgo = new Date(new Date().setDate(new Date().getDate()-1));

            if (campaignPopupClosedAt < threeDaysAgo && this.userData == undefined) {
                setTimeout(() => this.$refs.modal.show(), 3000);
            }else if(this.userData != undefined  && campaignBrewPopupClosedAt < oneDayAgo){
                setTimeout(() => this.$refs.modal.show(), 3000);
            }else if (this.userCoffeepreference && campaignBrewPopupClosedAt < oneDayAgo){
                setTimeout(() => this.$refs.modal.show(), 3000);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/_variables_home.scss";
    @import "../../styles/common_home.scss";
    .half-off{
        font-size: 14px;
        padding-right: 8px;
    }
</style>
