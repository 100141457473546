/*jshint globalstrict: false*/

import Vue from 'vue';
import CampaignModal from '../components/home/campaign-modal-home';

Vue.component('campaign-modal', CampaignModal);

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        el: '[data-behavior="campaign-modal-vue"]',
    });
});
