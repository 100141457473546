import Vue from 'vue';

export const ClickOutside = {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
        document.body.addEventListener('closevuepopovers',  el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
        document.body.removeEventListener('closevuepopovers',  el.clickOutsideEvent)
    },

    stopProp(event) { event.stopPropagation() }
};

Vue.directive('click-outside', ClickOutside);
